import { useState, useEffect } from "react";

import './Payment.css';

import { Link } from "react-router-dom";

import { GetDataPage } from "interface/Data";
import { SetListPag } from "interface/Page";
import { GetUserData } from "interface/Users";

export default function Page_Payment(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('payment'));
    const [ category, setCategory ] = useState(GetDataPage('category_payment'));
    const [ infUser, setInfUser ] = useState(0);
    
    function ShowDataUser(){        
        let intervalNivel = setInterval(() => {            
            if(GetUserData('user')){
                setInfUser(GetUserData('user')['nivel']);
                clearInterval(intervalNivel);
            }
        }, 200);
    }
    
    function ShowPriceBr(value){
        if(value){
            return value % 1 === 0 
            ? value.toLocaleString('pt-BR') 
            : value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        return '';
    }

    function OpenClick(value, type){
        props.ReturnIndex();
        SetListPag('typePayment', type);
        SetListPag('page', value);
    }

    function TypeAccess(showClassName, id, index, nivel){
        let showInfData = '';
        let showPayment = 
        <Link to="/payment" onClick={ ()=>{ OpenClick('payment', id); } } key={ index }>
            <div className={ showClassName }>
                Comece agora
            </div>
        </Link>
        let showAcquired = <div className={ showClassName + " acquired" }>Adquirido</div>;
        let showSignature = <div className={ showClassName + " signature_selected" }>Assinado</div>;
        
        switch (infUser) {
            case 0:
                    if(nivel == 0){
                        showInfData = showSignature;
                    }else {
                        showInfData = showPayment;
                    }
                break;

            case 1:
                    if(nivel == 1){
                        showInfData = showSignature;
                    }else {
                        if(nivel == 0){
                            showInfData = showAcquired;
                        }else {
                            showInfData = showPayment;
                        }
                    }
                break;
            case 2:
                    if(nivel == 2){
                        showInfData = showSignature;
                    }else {
                        if(nivel == 3){
                            showInfData = showPayment;
                        }else {
                            showInfData = showAcquired;
                        }
                    }
                break;
            case 3:
                    if(nivel == 3){
                        showInfData = showSignature;
                    }else {
                        showInfData = showAcquired;
                    }
                break;
        
            default:
                    showInfData = showPayment;
                break;
        }
        return showInfData;
    }

    useEffect(()=>{
        ShowDataUser();
        document.title = 'Planos de assinatura';
    }, []);

    // Adquiro, assinado e começe agora

    return(
        <div className="Page_Payment">
            <div className="container">
                <div className="title">{ dataPage.title }</div>
                <div className="subtitle">{ dataPage.subtitle }</div>
                <div className="list_table">
                    <table width="100%" cellPadding="0" cellSpacing="0">
                        <tbody>   
                            <tr> 
                                <td className="space_white"></td>
                                {
                                    dataPage.payment.map((elem, index)=>{                                        
                                        return(
                                            <td className="td_payment" key={ index }>
                                                <div className="list_inf_payment">
                                                    <div className="list_title" style={ { color: elem.color } }>    
                                                        {
                                                            elem.best_cost == "Não" ? <div className="best_choice"></div> :
                                                            <div className="best_choice best_choice_active">
                                                                melhor custo benefício
                                                            </div>
                                                        }       
                                                        { elem.title }
                                                        <div className="list_subtitle">
                                                            { elem.subtitle }
                                                        </div>
                                                    </div>
                                                    <div className="show_payment">
                                                        {
                                                            elem.type == "Free" ? 
                                                            <div className="payment_cash">Free</div> : 
                                                            <div className="show_type_payment">
                                                                <div className="payment_sigla">R$</div>
                                                                <div className="payment_cash">{ ShowPriceBr(elem.cash) }</div>
                                                                <div className="payment_data">
                                                                    <span className="align_botton_type">
                                                                        /
                                                                        {   
                                                                            elem.type == "Assinatura" ?
                                                                                elem.type_cash : "12x"
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="button">
                                                        {
                                                            TypeAccess('button_access', elem.id, index, elem['id_nivel'])
                                                        }

                                                        {/* {
                                                            elem['id_nivel'] === 3 && elem['id_nivel'] == infUser ? 
                                                            <div className="button_access acquired">Assinado</div> :
                                                                elem['id_nivel'] == infUser ? 
                                                                <div className="button_access acquired">Assinado</div> : 
                                                                    <Link to="/payment" onClick={ ()=>{ OpenClick('payment', elem.id); } } key={ index }>
                                                                        <div className="button_access">
                                                                            Comece agora ({ infUser })
                                                                        </div>
                                                                    </Link>
                                                        } */}
                                                    </div>
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            {
                                category.map((elem, index)=>{                                    
                                    return(
                                        <tr key={ index }>
                                            <td className="space_names">{ elem.name }</td>
                                            {
                                                dataPage.payment.map((elem_1, index_1)=>{
                                                    let opt_selected = '';
                                                    elem_1.category.map((elem_2, index_2)=>{
                                                        if(elem_2.id_category == elem.id){
                                                            opt_selected = 1;
                                                        }
                                                    });
                                                    return(                                
                                                        <td className="td_payment" key={ index_1 }>
                                                            <div className="payment_circle">
                                                                {
                                                                    opt_selected ? <div className="payment_active"/> : null
                                                                }
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="list_mobile">
                    {
                        dataPage.payment.map((elem, index)=>{
                            return(
                                <div className="show_payment_mobile" key={ index }>
                                    <div className="payment_mobile">
                                        {
                                            elem.best_cost == "Não" ? null : 
                                            <div className="mobile_active">
                                                melhor custo benefício
                                            </div>
                                        } 
                                        <div className="payment_type_mobile">
                                            {
                                                elem.type == "Free" ?
                                                <>                                                
                                                    <div className="mobile_title" style={ { color: elem.color } }>
                                                        { elem.title }
                                                    </div>                                       
                                                    <div className="mobile_subtitle">
                                                        { elem.subtitle }
                                                    </div>
                                                </> : 
                                                <>                                                 
                                                    <div className="mobile_title" style={ { color: elem.color } }>
                                                        { elem.title }
                                                    </div>                                    
                                                    <div className="mobile_subtitle show_type_payment">
                                                        <div className="payment_sigla">R$</div>
                                                        <div className="payment_cash">{ ShowPriceBr(elem.cash) }</div>
                                                        <div className="payment_data">
                                                            <span className="align_botton_type">
                                                                /
                                                                {   
                                                                    elem.type == "Assinatura" ?
                                                                        elem.type_cash : "12x"
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>  
                                                </>
                                            }
                                        </div>
                                        {
                                            elem.type != "free" ? null :
                                            <div className="mobile_subtitle">
                                                {  elem.subtitle }
                                            </div>
                                        }
                                        <div className="button">
                                            {
                                                TypeAccess('button_text', elem.id, index, elem['id_nivel'])
                                            }
                                            {/* <Link to="/payment" onClick={ ()=>{ OpenClick('payment', elem.id); } } key={ index }>
                                                <div className="button_text">
                                                    Comece agora
                                                </div>
                                            </Link> */}
                                        </div>
                                    </div>
                                    <div className="list_category_mobile">
                                        {
                                            elem.category.map((elem_1, index_1)=>{
                                                return(
                                                    <div className="" key={ index_1 }>
                                                        { elem_1.name }
                                                    </div>
                                                )                                    
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}