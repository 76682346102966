import { useState, useEffect } from "react";

import './Register.css';

import { SvgChekedReturn, SvgErro } from "components/SvgFile";

import { cepMask, cpfMask } from "services/Mask";

import { Register } from "services/Login";

import { SetModalData, SetModalState } from "interface/PopUp";

export default function Model_Register(props){

    const [ regName, setRegName ] = useState('');
    const [ regEmail, setRegEmail ] = useState('');
    const [ regLogin, setRegLogin ] = useState('');
    const [ regBirthDate, setRegBirthDate ] = useState('');
    const [ regPhone, setRegPhone ] = useState('');
    const [ regCountry, setRegCountry ] = useState('');
    const [ regAddress, setRegAddress ] = useState('');
    const [ regCep, setRegCep ] = useState('');
    const [ regCity, setRegCity ] = useState('');
    const [ regNeighborhood, setRegNeighborhood ] = useState('');
    const [ regNumber, setRegNumber ] = useState('');
    const [ regUf, setRegUf ] = useState('');
    const [ regCpf, setRegCpf ] = useState('');
    const [ regPass_1, setRegPass_1 ] = useState('');
    const [ regPass_2, setRegPass_2 ] = useState('');

    function RegisterUser(event){
        event.preventDefault();
        props.setLoading(true);
        Register(regName, regEmail, regCpf, regBirthDate, regPhone, regCountry, regLogin, regAddress, regCep, regCity, regNeighborhood, regNumber, regUf, regPass_1, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() }, ()=>{ CallbackEmail() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        props.CallbackSuccess();
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackEmail(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "msg_error", "text": "Já tem uma conta criado com o e-mail digitado, utilize outro e-mail!" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <form onSubmit={ RegisterUser } className="Model_Register">
            <div className="title_page">Cadastrar</div>
            <div className="div_row space_top">
                <div className="div_data_input space_div">
                    <input type="text" className="input_data" onChange={ (e)=>{ setRegName(e.target.value) } } value={ regName } required />
                    <span className="name_input">Nome completo*</span>
                </div>
                <div className="div_data_input space_div">
                    <input type="email" className="input_data" onChange={ (e)=>{ setRegEmail(e.target.value) } } value={ regEmail.replaceAll(' ', '') } required />
                    <span className="name_input">E-mail*</span>
                </div>
            </div>

            <div className="div_row space_top">
                <div className="div_data_input">
                    <input type="text" className="input_data cpf" onChange={ (e)=>{ setRegCpf(e.target.value) } } value={ cpfMask(regCpf) } />
                    <span className="name_input">CPF</span>
                </div>
                <div className="div_data_input">
                    <input type="date" onChange={ (e)=>{ setRegBirthDate(e.target.value) } } value={ regBirthDate } className="input_data birth_date" required />
                    <span className="name_input">Data Nascimento*</span>
                </div>
                <div className="div_data_input space_div">
                    <input type="text" className="input_data" onChange={ (e)=>{ setRegPhone(e.target.value) } } value={ regPhone } />
                    <span className="name_input">Telefone</span>
                </div>
            </div>

            <div className="div_row space_top">
                <div className="div_data_input space_div">
                    <input type="text" maxLength="10" className="input_data" onChange={ (e)=>{ setRegCountry(e.target.value) } } value={ regCountry } />
                    <span className="name_input">País</span>
                </div>
                <div className="div_data_input space_div">
                    <input type="text" className="input_data" onChange={ (e)=>{ setRegAddress(e.target.value) } } value={ regAddress } />
                    <span className="name_input">Endereço</span>
                </div>
                <div className="div_data_input">
                    <input type="text" onChange={ (e)=>{ setRegNumber(e.target.value) } } value={ regNumber } className="input_data cpf" />
                    <span className="name_input">Número</span>
                </div>
            </div>

            <div className="div_row space_top">
                <div className="div_data_input space_div">
                    <input type="text" className="input_data" onChange={ (e)=>{ setRegNeighborhood(e.target.value) } } value={ regNeighborhood } />
                    <span className="name_input">Bairro</span>
                </div>
                <div className="div_data_input space_div">
                    <input type="text" className="input_data" onChange={ (e)=>{ setRegCity(e.target.value) } } value={ regCity } />
                    <span className="name_input">Cidade</span>
                </div>
                <div className="div_data_input">
                    <input type="text" className="input_data uf" onChange={ (e)=>{ setRegUf(e.target.value) } } value={ regUf } />
                    <span className="name_input">UF</span>
                </div>
                <div className="div_data_input">
                    <input type="text" className="input_data cpf" onChange={ (e)=>{ setRegCep(e.target.value) } } value={ cepMask(regCep) } />
                    <span className="name_input">CEP</span>
                </div>
            </div>

            <div className="div_row space_top">
                <div className="div_data_input div_space_mobile">
                    <input type="password" minLength="5" className="input_data passw" onChange={ (e)=>{ setRegPass_1(e.target.value) } } value={ regPass_1 } required />
                    <span className="name_input">Senha*</span>
                </div>
                <div className="div_data_input div_space_mobile">
                    <input type="password" minLength="5" className="input_data passw" onChange={ (e)=>{ setRegPass_2(e.target.value) } } value={ regPass_2 } required />
                    <span className="name_input">Conf. da senha*</span>
                </div>
                {
                    regPass_1 == '' || regPass_2 == '' ? null :
                    <>
                        {
                            regPass_1 != regPass_2 ? 
                            <div className="div_data_input pass_checked">
                                <SvgErro color="#F00000" className="icons_pass" />
                                <span className="name_pass">Senhas diferentes</span>
                            </div>
                            : 
                            <div className="div_data_input pass_checked">
                                <SvgChekedReturn color="#ffffff" className="icons_pass" />
                            </div>
                        }
                    </>
                }
            </div>

            <div className="div_row space_top">
                <div className="checkbox">
                    <input type="checkbox" className="input_checkbox" required />
                </div>
                <div className="checkbox_text">
                    Eu li e concordo com os <b><a className="text_link" href="" target="_blank">Termos de Uso</a></b>*
                </div>
            </div>

            <div className="div_row space_top">
                <div className="checkbox">
                    <input type="checkbox" className="input_checkbox" required />
                </div>
                <div className="checkbox_text">
                    Eu li e concordo com a <b><a className="text_link" href="" target="_blank">Política de Privacidade</a></b>*
                </div>
            </div>

            <div className="div_button">
                {
                    regPass_1 == '' || regPass_2 == '' ? 
                    <div className="button_login">
                        É necessário preencher o campo de senha
                    </div> :
                    <>
                        {
                            regPass_1 != regPass_2 ? 
                            <div className="button_login">
                                As senhas devem ser iguais
                            </div> : 
                            <button type="submit" className="button_login">
                                Cadastrar
                            </button>
                        }
                    </>
                }
            </div> 
        </form>
    )
}