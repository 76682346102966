
let UserData = {
    "user" : {},
    "access_type" : 0  
};

let NotifyUserData = {
    "user" : [],
    "access_type" : []
}

export function SetUserData(key, value){
    UserData[key] = value;
    NotifyUserData[key].forEach(element => {
        element(value);
    });
}
export function GetUserData(key){
    return UserData[key];   
}
export function RegisterUserData(key, value){
    if(!NotifyUserData[key]){
        NotifyUserData[key] = []; 
    }
    NotifyUserData[key].push(value);
}

export function UnRegisterUserData(key, callback){
    if(NotifyUserData[key].length > 0){
        NotifyUserData[key] = NotifyUserData[key].filter((item) => {
            return item !== callback;
        });
    }
}