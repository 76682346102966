import React, { useState, useEffect } from "react";

import './OpenPayment.css';

import { SvgClose } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

export default function PopUP_OpenPayment(props){
    
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ count, setCount ] = useState(0);

    function ClosePopUp(){
        setCount(0);
        SetModalState('OpenPayment', false);
    }

    function CopyCode(){
        navigator.clipboard.writeText(modalData.qrCode);
        props.setStatusNotification(true);
        setTimeout(() => {
            props.setStatusNotification(false);
        }, 1500);
    }

    useEffect(()=>{
        RegisterModalData('OpenPayment', setModaldata);
        RegisterModalObserver('OpenPayment', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            const interval = setInterval(() => {
                setCount(prevCount => prevCount + 1);
            }, 1000);
        
            return () => clearInterval(interval);
        }
    }, [showPopUp]);

    useEffect(() => {
        if (count === 3) {
            console.log('modalData.type', modalData.type);
            
            if(modalData.type == 'Assinatura'){
                console.log('Aaaa');
                
                window.open(modalData.qrCodeFile, "_blank");
                ClosePopUp();
            }
        }
    }, [count]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all OpenPayment">
                    <div className="div_data">                            
                        <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                            <SvgClose color="#ffffff" className="icons" />
                        </div>                            
                        <div className="content">
                            <div className="pop_title space_top">
                                Curso { modalData.title }
                            </div>
                            {
                                modalData.type == 'Assinatura' ?
                                <div className="pop_text">
                                    Você será encaminhado para página de pagamento em
                                    <div className="div_count_payment">{ count }</div>
                                </div>
                                :
                                <>
                                    {
                                        modalData.optSelected == 'pix' || modalData.optSelected == 'ticket' ? 
                                        <> 
                                            <div className="pop_text">
                                                {
                                                    modalData.optSelected == 'pix' ? 
                                                    "Escaneie o QrCode abaixo para realizar o pagamento" :
                                                    "Clique no campo abaixo para efetuar o download do boleto"
                                                }
                                            </div>

                                            {
                                                modalData.optSelected == 'pix' ?
                                                    <div className="div_img">
                                                        <img alt="code" src={ "data:image/png;base64," + modalData.qrCodeFile } className="img_" />
                                                    </div>
                                                :
                                                <>
                                                    <div className="div_btn">
                                                        <a className="pop_div_button" href={ modalData.qrCodeFile }target="_blank" >
                                                            <div className="pop_button">
                                                                Visualizar boleto
                                                            </div>
                                                        </a>
                                                        <div className="pop_div_button details_course" onClick={ ()=>{ CopyCode() } }>
                                                            <div className="pop_button">
                                                                Copiar código
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className="pop_text">
                                                Foi encaminhado um email com as<br /> informações para efetuar o pagamento
                                            </div>
                                            {
                                                modalData.optSelected == 'pix' ?
                                                    <div className="div_btn">
                                                        <div className="pop_div_button" onClick={ ()=>{ CopyCode() } }>
                                                            <div className="pop_button">Copiar código</div>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                        </>
                                        : 
                                        <>
                                            <div className="pop_text">
                                                Pagamento gerado com sucesso, os cursos já estão liberados.
                                            </div>
                                            <div className="pop_text">
                                                Agradecemos pela preferencia.
                                            </div>
                                            <div className="div_btn">
                                                <div className="pop_div_button" onClick={ ()=>{ ClosePopUp() } }>
                                                    <div className="pop_button">Fechar</div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}