import { useState, useEffect } from "react";

import './Login.css';

import { SetListPag } from "interface/Page";
import { SetModalState } from "interface/PopUp";

import { Link } from "react-router-dom";

import { Access } from "services/Login";

import PopUp_RecoverPassword from "components/PopUp/RecoverPassword";

export default function Model_Login(props){

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function OpenClick(value){
        SetListPag('page', value);
    }

    function Register(event){
        event.preventDefault();
        Access('logar', email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.CallbackSuccess(true);
    }

    function CallbackError(){
        setMsgError('E-mail ou senha incorreta!');
    }

    function RecoverPassword(){
        SetModalState('RecoverPassword', true);
    }

    return(
        <>
            <form onSubmit={ Register } className="Model_Login">
                <div className="show_login">
                    {
                        props.origin == 'payment' ? 
                        <div className="title_page">Login</div> : null 
                    }
                    <div className="div_input">
                        <div className="">E-mail</div>
                        <div className="space_input">
                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } placeholder="teste@teste.com" value={ email } required />
                        </div>
                    </div>
                    <div className="div_input">
                        <div className="">Senha</div>
                        <div className="space_input">
                            <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } placeholder="****" value={ password } required />
                        </div>
                    </div>                            
                    <div className={ msgError ? "msg_error" : "msg_none" }>
                        { msgError }
                    </div>
                    <div className="div_button">
                        <button type="submit" className="button_login">Login</button>
                    </div>            
                    <div className="register">
                        <div className="text_1" onClick={ ()=>{ RecoverPassword() } }>Esqueci minha senha</div>
                        {
                            props.origin == 'payment' ? null : 
                            <Link to="/mentoring" onClick={ ()=>{ OpenClick('mentoring'); } }>
                                <div className="text_2">Inscreva-se</div>
                            </Link>
                        }
                    </div>
                </div>
            </form>
            <PopUp_RecoverPassword setLoading={ props.setLoading } />
        </>
    )
}