import Axios from 'axios';

import { cookie_dash, cookie_dash_id, cookie_dash_id_classe, cookie_dash_id_module, cookie_email, cookie_passw, cookie_site, cookiesRegister } from 'dataFixed';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';

import Cookies from 'universal-cookie';

export function RegisterPaymeny(type, typePayment, optSelected, name, email, cpf, nameCard, dueDate, cardNumber, cvv, installments, address, cep, city, neighborhood, number, uf, CallbackSuccess, OpenPaymentSignature, CallbackError){ 
    const cookies = new Cookies(); 
    const data = new FormData();

    data.append('type_page', 'register_payment');
    data.append('type_payment', typePayment);

    data.append('email', cookies.get(cookie_email));
    data.append('password', cookies.get(cookie_passw));
    
    data.append('opt_selected', optSelected);
    data.append('name', name);
    data.append('email', email);
    data.append('cpf', cpf);
    data.append('name_card', nameCard);
    data.append('due_date', dueDate);
    data.append('card_number', cardNumber);
    data.append('cvv', cvv);
    data.append('installments', installments);
    data.append('address', address);
    data.append('cep', cep);
    data.append('city', city);
    data.append('neighborhood', neighborhood);
    data.append('number', number);
    data.append('uf', uf);
    
    Axios({
        url : process.env.REACT_APP_API_URL + 'php/MercadoPago/payment_' + optSelected + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })   
    .then(response => {
        console.log('response.data - ', response.data);
        
        if(response.data['status'] == "ok"){
            if(response.data['type'] == 'pix' || response.data['type'] == 'ticket'){
                CallbackSuccess(response.data['qrcode'], response.data['qrcode_file']);
            }else {
                CallbackSuccess('', response.data['qrcode_file']);
            }

        }else {
            CallbackError();
        }
        
    }).catch((error)=>{
        console.log('error - ', error);
        
        CallbackError();
    })
}