import { useEffect, useState } from 'react';

import './Notification.css';

export default function Notification(props){

    const [ status, setStatus ] = useState(props.status);

    useEffect(()=>{
        setStatus(props.status);
    }, [props.status]);    

    return(            
        <div className={ status ? "copy_link" : "copy_none" }>
            <div className="div_text">
                Código copiado com sucesso!
            </div>
        </div>
    )
}