import { useState, useEffect } from "react";

import './Profile.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgUser } from "components/SvgFile";

import { Reg_AltData, Reg_AltPhoto } from "services/Login";
import { cepMask, cpfMask } from "services/Mask";

export default function Model_Profile(props){

    const [ dataUser, setDataUser ] = useState(GetUserData('user'));
    
    const [ name, setName ] = useState(GetUserData('user')['name']);
    const [ login, setLogin ] = useState(GetUserData('user')['login']);
    const [ email, setEmail ] = useState(GetUserData('user')['email']);
    const [ birthDate, setBirthDate ] = useState(GetUserData('user')['birth_date']);
    const [ phone, setPhone ] = useState(GetUserData('user')['phone']);
    const [ file, setFile ] = useState(GetUserData('user')['file']);
    const [ country, setCountry ] = useState(GetUserData('user')['country']);
    const [ address, setAddress ] = useState(GetUserData('user')['address']);
    const [ cep, setCep ] = useState(GetUserData('user')['cep']);
    const [ city, setCity ] = useState(GetUserData('user')['city']);
    const [ neighborhood, setNeighborhood ] = useState(GetUserData('user')['neighborhood']);
    const [ number, setNumber ] = useState(GetUserData('user')['number']);
    const [ uf, setUf ] = useState(GetUserData('user')['uf']);
    const [ cpf, setCpf ] = useState(GetUserData('user')['cpf']);
    const [ pass, setPass ] = useState('');

    function AddFile(value){
        if(value){
            setFile(value);
            props.setLoading(true);
            Reg_AltPhoto(value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_AltData(name, login, email, birthDate, phone, file, country, address, cep, city, neighborhood, number, uf, cpf, pass, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        setPass('');
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('user', setDataUser);
    }, []);
    
    useEffect(()=>{
        setDataUser(GetUserData('user'));

        setName(GetUserData('user')['name']);
        setLogin(GetUserData('user')['login']);
        setEmail(GetUserData('user')['email']);
        setBirthDate(GetUserData('user')['birth_date']);
        setPhone(GetUserData('user')['phone']);
        setFile(GetUserData('user')['file']);
        setCountry(GetUserData('user')['country']);
        setAddress(GetUserData('user')['address']);
        setCep(GetUserData('user')['cep']);
        setCity(GetUserData('user')['city']);
        setNeighborhood(GetUserData('user')['neighborhood']);
        setNumber(GetUserData('user')['number']);
        setUf(GetUserData('user')['uf']);
        setCpf(GetUserData('user')['cpf']);
    }, [dataUser]);

    return(
        <form onSubmit={ SaveData } className="Model_Profile">
            <div className="container">
                <div className="login_div_img">
                    <div className="login_img">
                        {
                            file == '' ? 
                            <SvgUser color="#FFFFFF" className="login_show_img" /> :
                            <img alt="img" src={ file } className="login_show_img" />
                        }
                    </div>
                    <div className="login_new_img">
                        <label>                                
                            Alterar foto
                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                        </label>
                    </div>
                </div>
                
                <div className="list_opt_login">
                    <div className="login_data_login">
                        <div className="dash_div_input space_div">
                            <input type="text" maxLength="100" onChange={ (e)=>{ setName(e.target.value) } } value={ name } className="dash_input" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="dash_div_input space_div">
                            <input type="email" maxLength="100" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } className="dash_input" required />
                            <span className="name_input">E-mail*</span>
                        </div>
                        <div className="dash_div_input dash_width_input">
                            <input type="date" onChange={ (e)=>{ setBirthDate(e.target.value) } } value={ birthDate } className="dash_input birth_date alt_width_input" required />
                            <span className="name_input">Data Nascimento</span>
                        </div>
                    </div>
                    <div className="login_data_login" style={ { marginTop: '16px' } }>
                        <div className="dash_div_input">
                            <input type="password" maxLength="100" onChange={ (e)=>{ setPass(e.target.value) } } className="dash_input input_login" />
                            <span className="name_input">Senha*</span>
                        </div>
                        <div className="dash_div_input">
                            <input type="text" maxLength="14" onChange={ (e)=>{ setCpf(e.target.value) } } value={ cpfMask(cpf) } className="dash_input cpf" />
                            <span className="name_input">CPF</span>
                        </div>
                        <div className="dash_div_input">
                            <input type="text" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phone } className="dash_input phone" />
                            <span className="name_input">Telefone</span>
                        </div>
                        <div className="dash_div_input dash_width_input">
                            <input type="text" onChange={ (e)=>{ setCountry(e.target.value) } } value={ country } className="dash_input cpf alt_width_input" />
                            <span className="name_input">País</span>
                        </div>
                    </div>
                    <div className="login_data_login" style={ { marginTop: '16px' } }>
                        <div className="dash_div_input space_div dash_width_input">
                            <input type="text" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } className="dash_input alt_width_input" />
                            <span className="name_input">Endereço</span>
                        </div>
                        <div className="dash_div_input">
                            <input type="text" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } className="dash_input cpf" />
                            <span className="name_input">Número</span>
                        </div>
                    </div>
                    <div className="login_data_login" style={ { marginTop: '16px' } }>
                        <div className="dash_div_input space_div dash_width_input">
                            <input type="text" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } className="dash_input alt_width_input" />
                            <span className="name_input">Bairro</span>
                        </div>
                        <div className="dash_div_input space_div dash_width_input">
                            <input type="text" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } className="dash_input alt_width_input" />
                            <span className="name_input">Cidade</span>
                        </div>
                        <div className="dash_div_input">
                            <input type="text" onChange={ (e)=>{ setUf(e.target.value) } } value={ uf } className="dash_input uf" maxLength="2" />
                            <span className="name_input">UF</span>
                        </div>
                        <div className="dash_div_input">
                            <input type="text" onChange={ (e)=>{ setCep(e.target.value) } } value={ cepMask(cep) } className="dash_input cpf" />
                            <span className="name_input">CEP</span>
                        </div>
                    </div>                            
                    <div className="div_register_data" style={ { marginTop: '16px' } }>
                        <button className="div_input space_div register_data">Salvar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}