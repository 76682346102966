import { useState, useEffect, useRef } from "react";

import './Payment.css';

import Cookies from 'universal-cookie';

import { cookie_email, cookie_passw } from "dataFixed";

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import Page_Payment from "components/Page/Payment";

import Model_Login from "../Login";
import Model_Register from "../Register";

import { useNavigate } from "react-router-dom";

import { SvgPaymentTicket } from "components/SvgFile";

import { cepMask, cpfMask } from "services/Mask";
import { RegisterPaymeny } from "services/Register/payment";

export default function Model_Payment(props){
        
    const cookies  = new Cookies();
    const [ showDetails, setShowDetails ] = useState(InitialData('all'));
    const [ showData, setShowData ] = useState(GetDataPage('settings'));
    const [ typePayment, setTypePayment ] = useState(GetListPag('typePayment'));
    const [ listCategory, setListCategory ] = useState(InitialData('category'));
    const [ optSelected, setOptSelected ] = useState('credit_card');

    const [ name, setName ] = useState(ShowDataUser('name'));
    const [ email, setEmail ] = useState(ShowDataUser('email'));
    const [ cpf, setCpf ] = useState(ShowDataUser('cpf'));

    const [ nameCard, setNameCard ] = useState('');
    const [ dueDate, setDueDate ] = useState('');
    const [ cardNumber, setCardNumber ] = useState('');
    const [ cvv, setCvv ] = useState('');
    const [ installments, setInstallments ] = useState(1);

    const [ address, setAddress ] = useState(ShowDataUser('address'));
    const [ cep, setCep ] = useState(ShowDataUser('cep'));
    const [ city, setCity ] = useState(ShowDataUser('city'));
    const [ neighborhood, setNeighborhood ] = useState(ShowDataUser('neighborhood'));
    const [ number, setNumber ] = useState(ShowDataUser('number'));
    const [ uf, setUf ] = useState(ShowDataUser('uf'));
      
    const navigate = useNavigate();
    const [ stage, setStage ] = useState(1); 

    function InitialData(type){
        if(GetListPag('typePayment') != ""){
            const newData = GetDataPage('payment').payment.find(item => item.id == GetListPag('typePayment'));            
            if(type == 'all'){
                return newData;
            }else {
                return newData['category'];
            }
        }
        if(type == 'category'){
            return [];
        }
        return "";
    } 

    function ShowDataUser(type){
        const newData = GetUserData('user');
        if(newData){
            return newData[type];
        }
        return '';
    }

    function NextStage(){
        if(cookies.get(cookie_passw) || cookies.get(cookie_email)){
            setName(ShowDataUser('name'));
            setEmail(ShowDataUser('email'));
            setCpf(ShowDataUser('cpf'));
    
            setNameCard('');
            setDueDate('');
            setCardNumber('');
            setCvv('');
            if(showDetails['type'] == "Assinatura"){
                setInstallments(1);
            }else {
                setInstallments(12);
            }
    
            setAddress(ShowDataUser('address'));
            setCep(ShowDataUser('cep'));
            setCity(ShowDataUser('city'));
            setNeighborhood(ShowDataUser('neighborhood'));
            setNumber(ShowDataUser('number'));
            setUf(ShowDataUser('uf'));
            setStage(2);
        }else {
            setStage(1);
        }
    }

    function InfDueDate(value){
        value = value.replace(/[^0-9/]/g, '');
        if (value.length === 2 && !value.includes('/')) {
            value += '/';
        }
        setDueDate(value);
    }

    function ShowCardNumber(value){
        let valueInf = value.replace(/\D/g, '');
        valueInf = valueInf.replace(/(\d{4})(?=\d)/g, '$1 ');
        setCardNumber(valueInf);
    }
    
    function ShowPriceBr(value){
        if (value !== undefined && value !== null) {
            return Number.isInteger(value) 
                ? value.toLocaleString('pt-BR') 
                : value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        return '';
    }

    function ListOption(){
        let priceAlt = showDetails['cash'];
        return (
            <>
                {
                    [...Array(12)].map((_, index) => {
                        const parcelas = index + 1;
                        const valorParcela = (priceAlt * 12) / parcelas;
                        if (parcelas === 1 || valorParcela >= 0.99) {
                            return (
                                <option key={parcelas} value={parcelas}>
                                    {parcelas}x de R$ {ShowPriceBr(valorParcela)} - sem juros
                                </option>
                            );
                        }
                        return null;
                    })
                }
            </>
        );
    }

    function InfTypePayment(){
        if(optSelected === 'credit_card'){
            return(
                <>
                    <div className="payment_cash">{ ShowPriceBr(showDetails.cash) }</div>
                    <div className="payment_data">
                        {
                            showDetails.type == 'Compra' ? "12x" : "/" + showDetails.type_cash 
                        }
                    </div>
                </>
            )

        }else {
            let priceAlt = ShowPriceBr(showDetails['cash'] * 12);
            return(
                <div className="payment_cash">{ priceAlt }</div>
            )
        }
    }

    function SavePayment(event){
        event.preventDefault();
        props.setLoading(true);
        
        RegisterPaymeny(showDetails['type'], showDetails['id_nivel'], optSelected, name, email, cpf, nameCard, dueDate, cardNumber, cvv, installments, address, cep, city, neighborhood, number, uf, OpenPaymentSignature, OpenPaymentSignature, ()=>{ CallbackError() });        
    }

    function OpenPaymentSignature(qrCode, qrCodeFile){
        props.setLoading(false);
        props.RegisterAccess();
        props.setStatusLogin(true);

        navigate("/login");
        SetListPag('page', 'login');

        document.getElementById('home').scrollTo({ top: 0, behavior: 'smooth' });
        SetModalData('OpenPayment', { "type": showDetails['type'], "title": showDetails.title, "subtitle": showDetails.subtitle, "qrCode": qrCode, "qrCodeFile": qrCodeFile, "optSelected": optSelected });
        SetModalState('OpenPayment', true);
    }

    function CallbackSuccess(){
        setTimeout(() => {
            setName(ShowDataUser('name'));
            setEmail(ShowDataUser('email'));
            setCpf(ShowDataUser('cpf'));
            setStage(2);
            props.RegisterAccess(true);
        }, 300);
    }

    function CallbackError(){
        document.getElementById('home').scrollTo({ top: 0, behavior: 'smooth' });
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    
    function CurrentData(){        
        switch (stage) {
            // informação sobre o curso
            case 0:
                return(
                    <>
                        <div className="title">Informações sobre a compra</div>
                        <div className="list_inf_payment">
                            <div className="show_details">
                                <div className="payment_title">
                                    <div className="" style={ { color: "#ffffff" } }>
                                        { showDetails.title }
                                    </div>
                                    {
                                        showDetails.type == 'Free' ? null :
                                        <div className="payment_show_cash">
                                            <div className="payment_sigla">
                                                R$
                                            </div>
                                            <div className="payment_cash">{ showDetails.cash }</div>
                                            <div className="payment_data">
                                                {
                                                    showDetails.type == 'Compra' ? "12x" : "/" + showDetails.type_cash 
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="payment_subtitle" style={ { color: showDetails.color } }>
                                    { showDetails.subtitle }
                                </div>
                                <div className="list_category">
                                    {
                                        listCategory.map((elem, index)=>{
                                            return(
                                                <div className="category_name" key={ index }>
                                                    { elem.name }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="div_next">
                                <div className="show_inf">
                                    {
                                        showDetails.type == 'Compra' ? 
                                        <>
                                            <div className="details_title">Detalhes da compra</div>
                                            
                                            <div className="details_inf" dangerouslySetInnerHTML={ { __html: showData.purchase.replaceAll('&#34;', '"') } } />
                                        </> :
                                        showDetails.type == 'Free' ? 
                                        <>                                  
                                            <div className="details_title">Assinatura sem custo</div>     
                                        </> :
                                        <>                                            
                                            <div className="details_title">Detalhe da sua assinatura</div>
                                            
                                            <div className="details_inf" dangerouslySetInnerHTML={ { __html: showData.text.replaceAll('&#34;', '"') } } />
                                        </>
                                    }                            
                                    {/* 
                                    <div className="details_inf">
                                        Clique no botão abaixo para ser redirecionado para página de pagamento.
                                    </div> 
                                    */}

                                    <div className="details_cancel">
                                        Para cancelamento ou demais informações favor entrar em contato.
                                    </div>
                                </div>
                                <div className="div_btn">                                 
                                    <div className="button_text" onClick={ ()=>{ NextStage() } }>
                                        Continuar
                                    </div>
                                </div>
                                <div className="div_btn">
                                    <div className="button_text button_return" onClick={ ()=>{ setStage(0); SetListPag('typePayment', ''); } }>
                                        Voltar
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )

            // login para pagamento
            case 1:
                return(
                    <>
                        <div className="title">É necessário fazer login para continuar</div>
                        <div className="div_data_login">
                            <div className="div_register">
                                <Model_Register ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } CallbackSuccess={ CallbackSuccess } origin="payment" />
                            </div>
                            <div className="div_login">
                                <Model_Login ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } CallbackSuccess={ CallbackSuccess } origin="payment" />
                            </div>
                        </div>
                    </>
                )
            
            // informação para compra
            case 2:
                if(showDetails['type'] == "Free"){
                    return(
                        <>
                            <div className="title">Curso adicionado a sua lista</div>
                            <div className="list_inf_payment">
                                <div className="show_details">
                                    <div className="payment_title">
                                        <div className="" style={ { color: "#ffffff" } }>
                                            { showDetails.title }
                                        </div>
                                    </div>
                                    <div className="payment_subtitle" style={ { color: showDetails.color } }>
                                        { showDetails.subtitle }
                                    </div>
                                    <div className="list_category">
                                        {
                                            listCategory.map((elem, index)=>{
                                                return(
                                                    <div className="category_name" key={ index }>
                                                        { elem.name }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="div_next">
                                    <div className="show_inf">
                                        <div className="details_title">Assinatura sem custo</div>
                                        <div className="details_cancel">
                                            Para cancelamento ou demais informações favor entrar em contato.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }else {
                    // showDetails['type'] == "Assinatura"
                    return(
                        showDetails['type'] == "Assinatura" ?
                        <>
                            <div className="title">Confira os dados abaixo para iniciar sua assinatura</div>
                            <form className="show_data_inf" onSubmit={ SavePayment }>
                                <div className="div_card">
                                    <div className="list_data_opt">
                                        <div className="div_input">
                                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="100" required />
                                            <span>Nome*</span>
                                        </div>
                                        <div className="div_input">
                                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="100" required />
                                            <span>E-mail*</span>
                                        </div>
                                        <div className="div_input not_flex_grow">
                                            <input type="text" onChange={ (e)=>{ setCpf(e.target.value) } } value={ cpf ? cpfMask(cpf) : cpf } required maxLength="14" className="cpf" />
                                            <span>CPF*</span>
                                        </div>
                                    </div>

                                    <div className="div_btn">
                                        <button className="generator_payment" type="submit">Ir para pagamento</button>
                                    </div>
                                </div>

                                <div className="list_inf_payment inf_course">
                                    <div className="show_details">
                                        <div className="payment_title">
                                            <div className="">
                                                { showDetails.title }
                                            </div>
                                            <div className="payment_show_cash" style={ { color: showDetails.color } }>
                                                <div className="payment_sigla">
                                                    R$
                                                </div>
                                                { 
                                                    InfTypePayment()
                                                }
                                            </div>
                                        </div>

                                        <div className="payment_subtitle">
                                            { showDetails.subtitle }
                                        </div>
                                        
                                        <div className="list_category">
                                            {
                                                listCategory.map((elem, index)=>{
                                                    return(
                                                        <div className="category_name" key={ index }>
                                                            { elem.name }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                        :                        
                        <>
                            <div className="title">Escolha a forma de pagamento</div>
                            <form className="show_data_inf" onSubmit={ SavePayment }>
                                <div className="div_card">
                                    <div className="list_opt">
                                        <div className="opt_payment" onClick={ ()=>{ setOptSelected('credit_card'); } }>
                                            <div className="div_img">
                                                <img alt="payment" src="./assets/icons_svg/cartao.svg" />
                                            </div>
                                            <div className="type_payment">Cartão de crédito</div>
                                            {
                                                optSelected === "credit_card" ? <div className="opt_selected" /> : null
                                            }
                                        </div>

                                        <div className="opt_payment" onClick={ ()=>{ setOptSelected(showDetails['type'] == 'Assinatura' ? 'credit_card' : 'debit_card'); } }>
                                            <div className="div_img">
                                                <img alt="payment" src="./assets/icons_svg/cartao.svg" />
                                            </div>
                                            <div className="type_payment">Cartão de débito</div>
                                            {
                                                optSelected === "debit_card" ? <div className="opt_selected" /> : null
                                            }
                                        </div>

                                        <div className="opt_payment" onClick={ ()=>{ setOptSelected(showDetails['type'] == 'Assinatura' ? 'credit_card' : 'pix'); } }>
                                            <div className="div_img">
                                                <img alt="payment" src="./assets/icons_svg/pix.svg" />
                                            </div>
                                            <div className="type_payment">Pix</div>
                                            {
                                                optSelected === "pix" ? <div className="opt_selected" /> : null
                                            }
                                        </div>

                                        <div className="opt_payment" onClick={ ()=>{ setOptSelected(showDetails['type'] == 'Assinatura' ? 'credit_card' : 'ticket'); } }>
                                            <div className="div_img">
                                                <SvgPaymentTicket className="icons" />
                                            </div>
                                            <div className="type_payment">Boleto</div>
                                            {
                                                optSelected === "ticket" ? <div className="opt_selected" /> : null
                                            }
                                        </div>
                                    </div>

                                    <div className="list_data_opt">
                                        <div className="div_input">
                                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="100" required />
                                            <span>Nome*</span>
                                        </div>
                                        <div className="div_input">
                                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="100" required />
                                            <span>E-mail*</span>
                                        </div>
                                        <div className="div_input not_flex_grow">
                                            <input type="text" onChange={ (e)=>{ setCpf(e.target.value) } } value={ cpf ? cpfMask(cpf) : cpf } required maxLength="14" className="cpf" />
                                            <span>CPF*</span>
                                        </div>
                                    </div>

                                    {
                                        optSelected === 'debit_card' || optSelected === 'credit_card' ?
                                            <div className="list_data_opt">
                                                <div className="div_input">
                                                    <input type="text" onChange={ (e)=>{ setNameCard(e.target.value) } } value={ nameCard } required className="name_card" />
                                                    <span>Nome impresso no cartão*</span>
                                                </div>
                                                <div className="div_input not_flex_grow">
                                                    <input type="text" onChange={ (e)=>{ ShowCardNumber(e.target.value) } } value={ cardNumber } placeholder="1234 5678 9012 3456" maxLength={ 19 } required className="card_number" />
                                                    <span>Número do cartão*</span>
                                                </div>
                                                <div className="div_input not_flex_grow">
                                                    <input type="text" className="cvv" onChange={ (e)=>{ setCvv(e.target.value) } } value={ cvv } required={ true } id="cvv" maxLength={ 4 } />
                                                    <span>CVV*</span>
                                                </div>
                                                <div className="div_input not_flex_grow">
                                                    <input type="text" onChange={ (e)=>{ InfDueDate(e.target.value) } } value={ dueDate } required={ false } id="expiry-date" pattern="\d{2}/\d{4}" maxLength={ 7 } placeholder="mm/aaaa" className="due_date" />
                                                    <span>Data de validade*</span>
                                                </div>
                                                {
                                                    optSelected === 'credit_card' ?
                                                        <div className="div_input not_flex_grow">
                                                            <select id="installments" className="installments" onChange={ (e)=>{ setInstallments(e.target.value) } } value={ installments } required>
                                                                { ListOption() }
                                                            </select>
                                                            <span>Qtd de parcelas*</span>
                                                        </div>
                                                    : null
                                                }
                                            </div>
                                        : null
                                    }

                                    <div className="list_data_opt">
                                        <div className="div_input">
                                            <input type="text" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } maxLength="100" required />
                                            <span>Endereço*</span>
                                        </div>
                                        <div className="div_input not_flex_grow">
                                            <input type="text" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } maxLength="10" className="cpf" required />
                                            <span>Número*</span>
                                        </div>
                                        <div className="div_input not_flex_grow">
                                            <input type="text" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } className="city" maxLength="100" required />
                                            <span>Bairro*</span>
                                        </div>
                                        <div className="div_input not_flex_grow">
                                            <input type="text" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } maxLength="100" className="city" required />
                                            <span>Cidade*</span>
                                        </div>
                                        <div className="div_input not_flex_grow">
                                            <input type="text" onChange={ (e)=>{ setUf(e.target.value) } } value={ uf } required maxLength="3" className="uf" />
                                            <span>UF*</span>
                                        </div>
                                        <div className="div_input not_flex_grow">
                                            <input type="text" onChange={ (e)=>{ setCep(e.target.value) } } value={ cep ? cepMask(cep) : cep } required maxLength="14" className="cep" />
                                            <span>CEP*</span>
                                        </div>
                                    </div>

                                    <div className="div_btn">
                                        <button className="generator_payment" type="submit">Finalizar</button>
                                    </div>
                                </div>

                                <div className="list_inf_payment inf_course">
                                    <div className="show_details">
                                        <div className="payment_title">
                                            <div className="">
                                                { showDetails.title }
                                            </div>
                                            <div className="payment_show_cash" style={ { color: showDetails.color } }>
                                                <div className="payment_sigla">
                                                    R$
                                                </div>
                                                { 
                                                    InfTypePayment()
                                                }
                                            </div>
                                        </div>

                                        <div className="payment_subtitle">
                                            { showDetails.subtitle }
                                        </div>
                                        
                                        <div className="list_category">
                                            {
                                                listCategory.map((elem, index)=>{
                                                    return(
                                                        <div className="category_name" key={ index }>
                                                            { elem.name }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </>
                    )
                }                
        }
    }

    useEffect(()=>{
        setTimeout(() => {
            NextStage();
        }, 300);
        RegisterListPag('typePayment', setTypePayment);
        RegisterListPag('typePayment', setTypePayment);
    }, []);

    useEffect(()=>{
        setShowDetails(InitialData('all'));
        setListCategory(InitialData('category'));
    }, [typePayment]);
    
    return(
        typePayment == "" ?  <Page_Payment ReturnIndex={ props.ReturnIndex } CkickPage={ props.CkickPage } setLoading={ props.setLoading } origin="home" /> :
        <div className="Model_Payment">
            <div className="container">
                { CurrentData() }
            </div>
        </div>
    )
}