// Connection type
export const connectionPage = '.';
// export const connectionPage = 'http://localhost/React_JS/3_crazy_pixel/site_v5_final/public';

// Cookies type
// export const cookiesRegister = './';
// export const cookiesRegister = 'crazypixel.com.br/';
export const cookiesRegister = 'previa.crazypixel.com.br//';

// Cookies name
export const cookie_site = 'crazy_pixel_site';
export const cookie_page = 'crazy_pixel_page';
export const cookie_passw = 'crazy_pixel_passw';
export const cookie_email = 'crazy_pixel_email';

export const cookie_dash = 'crazy_pixel_dash';
export const cookie_dash_id = 'crazy_pixel_dash_id';
export const cookie_dash_id_module = 'crazy_pixel_dash_id_module';
export const cookie_dash_id_classe = 'crazy_pixel_dash_id_classe';

export const cookie_type_payment = 'crazy_pixel_payment';
export const cookie_type_stage_payment = 'crazy_pixel_stage';

export const color_access = [
    {
        "id": 0,
        "color": "#97C107"
    },
    {
        "id": 1,
        "color": "#A8662D"
    },
    {
        "id": 2,
        "color": "#D3D3D3"
    },
    {
        "id": 3,
        "color": "#FFB800"
    }
]

// Current date
const day = new Date();

// Add 3 days
const add3Days = new Date();
export const cookie_expires = add3Days.setDate(day.getDate() + 3);