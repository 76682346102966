import { useState, useEffect } from "react";

import './CourseDashDetails.css';

import Vimeo from '@u-wave/react-vimeo';

import Axios from 'axios';

import TextareaAutosize from 'react-textarea-autosize';

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, SetListDataSingle } from "interface/Data";

import { SvgChekedReturn, SvgDownload, SvgLockClose, SvgLockOpen, SvgPlay, SvgReturn, SvgSetaDown, SvgUpload, SvgUploadFile } from "components/SvgFile";

import { Reg_AttendedClasses, Reg_ClassesFinishedPorcentagem, Reg_ClassesUploadFile, Reg_Conclusion } from "services/Login";

export default function Model_CourseDashDetails(props){

    const [ page, setPage ]               = useState(GetListPag('pageDash'));
    const [ user, setUser ]               = useState(GetUserData('user'));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('course'));
    const [ course, setCourse ]           = useState(InitialData());
    const [ showModules, setShowModules ] = useState(course.list_module);
    const [ classeCont, setClasseCont ]   = useState(user.continue.filter(item => item.id_course == GetListPag('pageDash_id_course')));
    const [ listFile, setListFile ]       = useState(ShowFileUser());
    
    const [ showConcl, setShowConcl ]   = useState(false);
    const [ textConcl, setTextConcl ]   = useState('');
    const [ conclusion, setConclusion ] = useState(CheckedClasse('total'));
    const [ optActive, setOptActive ]   = useState('tips');
    const [ listConcl, setListConcl ]   = useState(GetDataPage('course_questions'));

    /* inf course */
    const [ classeTitle, setClasseTitle ]       = useState('');
    const [ classeType, setClasseType ]         = useState('');
    const [ classeVideo, setClasseVideo ]       = useState('');
    const [ classeTips, setClasseTips ]         = useState('');
    const [ classeLink, setClasseLink ]         = useState('');
    const [ classeDownload, setClasseDownload ] = useState('');
    /* end */

    /* time classe */
    const [ timeVideo, setTimeVideo ]     = useState(0);
    const [ timeWatched, setTimeWatched ] = useState(0);
    const [ timePorcent, setTimePorcent ] = useState(0);
    /* end */

    function InitialData(){
        const newData = dataPage.find(item => item.id == GetListPag('pageDash_id_course'));        
        return newData;
    }

    function ShowFileUser(){
        const newData = GetUserData('user').list_files.filter(item => item.id_course == GetListPag('pageDash_id_course'));
        return newData;
    }

    function CountPorcentVideo(){
        if(timeVideo !=0 || timeWatched !=0){
            let porcentTotal = timeVideo * 80 / 100;            
            if(Math.round(timeWatched) > 0){
                if(Math.round(timeWatched) >= Math.round(porcentTotal)){
                    Reg_ClassesFinishedPorcentagem(user.id, GetListPag('pageDash_id_course'), GetListPag('pageDash_id_module'), GetListPag('pageDash_id_classe'), ()=>{ UpdateClasse() }); 
                }
            }
        }
    }

    function CheckedClasse(type){
        const newData = GetUserData('user')['continue'].filter(item => item.id_course == GetListPag('pageDash_id_course'));
        if(type == 'classe'){
            return newData;
        }else {
            const qtdClasse = [];
            showModules.map((elem, index)=>{
                if(user.nivel == 3){
                    qtdClasse.push(elem.list_classe.length);
                }else {
                    let showQtdClasse = elem.list_classe.filter(item => item.type == 'classe');
                    qtdClasse.push(showQtdClasse.length);
                }
            })

            let total = 0;
            qtdClasse.map((elem, index)=>{
                total = elem + total;
            });
            
            if(newData.length == total){
                if(user.conclusion_response.find(item => item.id_course == GetListPag('pageDash_id_course'))){
                    return 2;
                }else {
                    return 1;
                }

            }else {
                return 0;
            }
        }
    }
    
    function ReturnPage(){
        SetListPag('pageDash', 'course');  
        SetListPag('pageDash_id_course', 'remuve');  
        SetListPag('pageDash_id_module', 'remuve');  
        SetListPag('pageDash_id_classe', 'remuve');  
                
        const newData = [];
        GetDataPage('course').map((elem, index)=>{
            elem.list_module.map((elem_1, index_1)=>{
                elem_1.status = false;
                elem_1.list_classe.map((elem_2, index_2)=>{
                    elem_2.status = false;
                });
            })
            newData.push(elem);
        })
        SetListDataSingle('course', newData);
    }

    function OpenMoodule(id, index, value, qtdClasse){
        if(id !=0){
            const newData = [...showModules];
            newData[index]['status'] = value;
            setShowModules(newData);
        }

        let accordion = document.getElementById(id);
        if(value == true){
            accordion.style.maxHeight = (accordion.scrollHeight + (qtdClasse * 22) + 80) + "px";
            accordion.style.padding   = "8px 8px";
            
        }else {
            accordion.style.maxHeight = null; 
            accordion.style.padding   = "0px 8px";
        }
    }

    function SaveInputAccess(idModule, idClasse, status){
        if(classeCont.findIndex(item => item.id_classe == idClasse) != -1){
            let indexCourse = classeCont.findIndex(item => item.id_classe == idClasse);
            const newData = [...classeCont];
            newData[indexCourse]['status'] = status;
            setClasseCont(newData);
        }

        props.setLoading(true);
        Reg_AttendedClasses(user.id, GetListPag('pageDash_id_course'), idModule, idClasse, status, ()=>{ UpdateClasse() });
    }

    function OpenClasse(idModule, idClasse){ 
        setOptActive('tips');

        SetListPag('pageDash_id_module', idModule);
        SetListPag('pageDash_id_classe', idClasse);
        
        const saveDataClasse = [];
        showModules.map((elem, index)=>{
            elem.list_classe.map((elem_1, index_1)=>{
                if(elem_1.id == idClasse){
                    elem_1.status = true;
                }else {
                    elem_1.status = false;
                }
            });
            saveDataClasse.push(elem);
        });
        setShowModules(saveDataClasse);

        const newData   = course.list_module.find(item => item.id == idModule);
        const newClasse = newData.list_classe.find(item => item.id == idClasse);

        setClasseTitle(newClasse['title']);
        setClasseType(newClasse['type']);
        setClasseVideo(newClasse['video']);
        setClasseTips(newClasse['tips']);
        setClasseLink(newClasse['link']);
        setClasseDownload(newClasse['downloads']);
    }

    function CheckedStatusClasse(dataCourse, idModule, idClasse){
        let classesAttended = {};        
        if(classeCont.find(item => item.id_classe == idClasse)){
            classesAttended = classeCont.find(item => item.id_classe == idClasse);
        }
        
        // classe - upload
        let showUpload = 
        <>
            <div className="list_classes_title" onClick={ ()=>{ OpenClasse(idModule, idClasse) } }>Upload</div>
            <div className="list_classes_access" onClick={ ()=>{ OpenClasse(idModule, idClasse) } }>
                {
                    Object.keys(classesAttended).length > 0 ?
                    <SvgUpload className="icons" color="#2cb742" /> :
                    <SvgUpload className="icons" color="#ffffff" />
                }
            </div>
        </>
        
        // classe - true
        let showClasseOpen = 
        <>
            <div className="list_classes_input">
                {
                    Object.keys(classesAttended).length > 0 ?
                    <input type="checkbox" className="input_checkbox" value="true" onChange={ (e)=>{ SaveInputAccess(idModule, idClasse, !classesAttended.status); } } checked={ classesAttended.status } />
                    :
                    <input type="checkbox" className="input_checkbox" value="true" onChange={ (e)=>{ SaveInputAccess(idModule, idClasse, true); } } />
                }
            </div>
            <div className="list_classes_title" onClick={ ()=>{ OpenClasse(idModule, idClasse) } }>{ dataCourse.title }</div>
            <div className="list_classes_access" onClick={ ()=>{ OpenClasse(idModule, idClasse) } }>
                <SvgPlay className="icons" color="#ffffff" />
            </div>
        </>;

        // classe - false
        let showClasseClose = 
        <>
            <div className="list_classes_title">{ dataCourse.title }</div>
            <div className="list_classes_access">
                <SvgLockClose className="icons" color="#ffffff" />
            </div>
        </>;

        // classe - free 
        let showClasseFree =
        <>
            <div className="list_classes_input">
                {
                    Object.keys(classesAttended).length > 0 ? 
                    <input type="checkbox" className="input_checkbox" value="true" onChange={ (e)=>{ SaveInputAccess(idModule, idClasse, !classesAttended.status); } } checked={ classesAttended.status } />
                    :
                    <input type="checkbox" className="input_checkbox" value="true" onChange={ (e)=>{ SaveInputAccess(idModule, idClasse, true); } } />
                }
            </div>
            <div className="list_classes_title" onClick={ ()=>{ OpenClasse(idModule, idClasse) } }>{ dataCourse.title }</div>
            <div className="list_classes_access" onClick={ ()=>{ OpenClasse(idModule, idClasse) } }>
                <div className="free">Free</div>
                <SvgLockOpen className="icons" color="#ffffff" />
            </div>
        </>;

        if(dataCourse.type == 'upload'){
            if(user.nivel == 3){
                return showUpload;
            }

        }else {
            switch (user.nivel) {
                // free
                case 0:
                    if(course.free == true){
                        return showClasseOpen;

                    }else if(dataCourse.free == true){
                        return showClasseFree

                    }else {
                        return showClasseClose;
                    }

                // bronze
                case 1:
                    if(course.bronze == true){
                        return showClasseOpen;

                    }else if(dataCourse.free == true){
                        return showClasseFree
                        
                    }else {
                        return showClasseClose;
                    }

                // prata
                case 2:
                    if(course.prata == true){
                        return showClasseOpen;

                    }else if(dataCourse.free == true){
                        return showClasseFree
                        
                    }else {
                        return showClasseClose;
                    }
                                            
                // ouro
                case 3:                
                    return showClasseOpen;
            }
        }
    }

    function UpdateClasse(){
        props.setLoading(false);
        setCourse(InitialData());        
        setShowModules(course.list_module);
        setConclusion(CheckedClasse('total'));
        setListFile(ShowFileUser());

        setClasseCont(GetUserData('user')['continue'].filter(item => item.id_course == GetListPag('pageDash_id_course')));
    }

    function HandleOpt(index, value){
        const newData = [...listConcl];
        newData[index]['opt'] = value;        
        setListConcl(newData);
    }

    function SaveFileClasses(value){
        props.setLoading(true);
        Reg_ClassesUploadFile(user.id, GetListPag('pageDash_id_course'), GetListPag('pageDash_id_module'), GetListPag('pageDash_id_classe'), value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });  
    }

    function SaveConclusion(event){
        event.preventDefault();
        props.setLoading(true);
        
        Reg_Conclusion(user.id, GetListPag('pageDash_id_course'), textConcl, listConcl, ()=>{ CallbackSuccess(); setConclusion(2) }, ()=>{ CallbackError() }); 
    }

    function CallbackSuccess(){
        UpdateClasse();
        setShowConcl(false);
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        UpdateClasse();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function CheckedTextA(){
        setTimeout(() => {
            document.querySelectorAll('.text_href').forEach(infP => {
                infP.querySelectorAll('a').forEach(link => {
                    link.setAttribute('target', '_blank');
                });
            });
        }, 1000);
    }

    function ClickDownload(fileUrl, filename){
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterUserData('user', setUser);
        RegisterDataPage('course', setDataPage);

        CheckedTextA();
    }, []);

    useEffect(()=>{
        CountPorcentVideo();
    }, [timeWatched]);

    useEffect(()=>{
        setClasseCont(GetUserData('user')['continue'].filter(item => item.id_course == GetListPag('pageDash_id_course')));
        setListFile(ShowFileUser());
    }, [GetUserData('user')]);

    useEffect(()=>{
        document.querySelectorAll('a').forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer'); // Segurança contra ataques
        });
    }, [optActive]);
    
    return(
        <div className="Model_CourseDashDetails">
            <div className="container">
                <div className="div_title_course">
                    <div className="list_opt_topic">
                        <div className="div_img">
                            <img alt="img" src={ course.file } className="img_course" />
                        </div>
                        <div className="div_title">                            
                            { course.title }
                            {
                                classeTitle == "" ? null :
                                " - " + classeTitle
                            }
                        </div>
                    </div>
                    <div className="list_opt_topic list_opt_topic_course" onClick={ ()=>{ ReturnPage(); } }>
                        <SvgReturn color="#ffffff" className="icon_arrow" />
                        Voltar
                    </div>
                </div>

                <div className="div_data_course">
                    <div className="inf_course">
                        {
                            showConcl ?
                            <div className="div_conclusion">
                                <form onSubmit={ SaveConclusion }>                                    
                                    {
                                        conclusion == 1 ? 
                                        <> 
                                            <div className="img">
                                                <img alt="img" src={ course.file } className="img_" />
                                                <div className="div_marking">
                                                    <div className="marking_triangle" />
                                                    <div className="">Completo</div>
                                                </div>
                                            </div>

                                            <div className="conclusion">
                                                <div className="">Parabéns, você concluiu a aula de</div>
                                                <div className="name_course">{ course.title }</div>
                                            </div>

                                            <div className="conclusion_text">
                                                <div className="">
                                                    Se quiser você pode postar seu projeto no canal do Discord #portfolio. se tiver dúvidas de como fazer isso <a href="" className="a_discord" target="_blank">click aqui.</a>
                                                </div>
                                                <div className="">
                                                    Buscando a maxima satisfação de nossos alunos, pedimos que você avalie nossa aula. abaixo segue um questionário que pode ser respondido em menos de 5 minutos. Obrigado
                                                </div> 
                                            </div>
                                            
                                            {
                                                listConcl.map((elem, index)=>{
                                                    return(
                                                        <div className="conclusion_text" key={ index }>
                                                            <div className="border_top" />
                                                            <div className="conclusion_title">{ elem.title }</div>
                                                            <div className="" dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"') : "" } } />
                                                            <div className="list_opt_click">
                                                                {
                                                                    elem.list.map((elem_1, index_1)=>{
                                                                        return(
                                                                            <div className="div_opt" key={ index_1 }>
                                                                                <div className="div_opt_number">
                                                                                    { index_1 + 1 }
                                                                                </div>
                                                                                <div className="div_opt_input">
                                                                                    <input type="radio" className="input_radio" name={ "btn_" + index } value={ 1 } onChange={ (e)=>{ HandleOpt(index, elem_1.opt) } } required />
                                                                                </div>
                                                                                <div className="div_opt_title">
                                                                                    { elem_1.title }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="conclusion_text">
                                                <div className="border_top" />
                                                <div className="conclusion_title">Opinião</div>
                                                <div className="">
                                                    Descreva aqui sua opinião a respeito desta aula, o que gostou mais, o que aprendeu de novo ou o que poderia tornar esta aula melhor para você e outros alunos. (Resposta Opcional)
                                                </div>
                                            </div>

                                            <div className="conclusion_text">
                                                <TextareaAutosize className="input_text" onChange={ (e)=>{ setTextConcl(e.target.value) } } value={ textConcl } placeholder="Escreva aqui sua opnião..." />
                                            </div>                                    
                                            
                                            <div className="div_save">
                                                <button type="submit" className="div_save_name">
                                                    Enviar
                                                </button>
                                            </div>
                                        </> :
                                        <>
                                            <div className="conclusion_text div_final">
                                                <div className="title_final">
                                                    Obrigado!
                                                </div>
                                                <div className="" style={ { textAlign: "center" } }>
                                                    Muito Obrigado por suas respostas, isso nos ajudará a criar um conteúdo ainda melhor para você! 

                                                    Se você quiser, pode dos dar um feedback escrito.
                                                </div>
                                            </div>
                                        </>
                                    }                                    
                                </form>
                            </div>
                            :
                            
                            classeType == "" ?
                            <>
                                <div className="div_video">
                                    {
                                        course.type_video == "Vimeo" ?
                                        <Vimeo className="div_show_video" width="100%" height="100%" video={ course.video } /> :
                                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + course.video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
                                    }
                                </div>
                                <div className="div_text">
                                    <div className="text" dangerouslySetInnerHTML={ { __html: course.text ? course.text.replaceAll('&#34;', '"') : "" } } />
                                </div>
                            </> :
                            <>
                                {
                                    classeType == "upload" ? 
                                    <div className="div_upload">
                                        <label className="div_border">
                                            <div className="div_upload_icons">
                                                <SvgUploadFile className="icons_upload" color="#ffffff" />
                                            </div>
                                            <div className="div_upload_text">
                                                <p>Arraste seus arquivos aqui,</p>
                                                <p>ou <span className="highlight">Procure no Computador.</span></p> 
                                            </div>
                                            <div className="div_upload_text">
                                                <p>Apenas arquivo no formato .txt e .blend</p> 
                                            </div>
                                            <input type="file" className="upload_file" onChange={ (e)=>{ SaveFileClasses(e.target.files[0]) } } accept=".txt, .blend" />
                                        </label>
                                        {
                                            listFile.length > 0 ? 
                                            <div className="list_file_upload">
                                                {
                                                    listFile.map((elem, index)=>{
                                                        return(
                                                            <div className="show_files_upload" key={ index }>
                                                                <div className="show_name_file">
                                                                    {
                                                                        elem.status == 0 ? null : 
                                                                        <div className={ "div_status status_" + elem.status } />
                                                                    }
                                                                    { elem.title }
                                                                </div>
                                                                <div className="show_date_file">
                                                                    <div className="">
                                                                        { elem.date }
                                                                    </div>
                                                                    <a href={ elem.file } download={ elem.title } target="_blank" className="download_icons">
                                                                        <SvgDownload className="icons" color="#ffffff" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> : null
                                        }
                                    </div> :
                                    <div className="div_video">

                                        <Vimeo className="div_show_video" width="100%" height="100%" onTimeUpdate={ (e)=>{ setTimeVideo(e.duration); setTimeWatched(e.seconds); setTimePorcent(e.percent) } } video={ classeVideo } />
                                    </div>
                                }
                                <div className="dash_opt_video">
                                    {
                                        classeTips == '<p><br></p>' || classeTips == "" ? null : 
                                        <div className={ optActive == "tips" ? "click_active" : "opt_click" } onClick={ ()=>{ setOptActive('tips') } }>Dicas</div>
                                    } 
                                    {
                                        classeDownload.length == 0 ? null : 
                                        <div className={ optActive == "downloads" ? "click_active" : "opt_click" } onClick={ ()=>{ setOptActive('downloads') } }>Download</div>
                                    }                                        
                                    {
                                        classeLink == '<p><br></p>' || classeLink == "" ? null : 
                                        <div className={ optActive == "links" ? "click_active" : "opt_click" } onClick={ ()=>{ setOptActive('links') } }>Links</div>
                                    }
                                </div>
                                <div className="dash_show_click_opt_video">
                                    {
                                        optActive == "tips" ?
                                        <div className="text text_href" dangerouslySetInnerHTML={ { __html: classeTips ? classeTips.replaceAll('&#34;', '"') : "" } } /> : null
                                    }
                                    {
                                        optActive == "downloads" ?
                                        <div className="download">
                                            {
                                                classeDownload.length > 0 ?
                                                classeDownload.map((elem, index)=>{
                                                    let ext = elem.file_used.split('.');
                                                    return(
                                                        <div onClick={ ()=>{ ClickDownload(elem.file, elem.title + "." + ext[1]) } } key={ index }>
                                                            <div className="data">
                                                                <div className="img">
                                                                    <img alt="" src={ elem.img } className="img_" />
                                                                </div>
                                                                <div className="download_title">
                                                                    { elem.title }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }):
                                                <div className="">
                                                    Nenhum arquivo encontrado...
                                                </div>
                                            }
                                        </div> : null
                                    }
                                    {
                                        optActive == "links" ?
                                        <div className="text text_href" dangerouslySetInnerHTML={ { __html: classeLink ? classeLink.replaceAll('&#34;', '"') : "" } } /> : null
                                    }
                                    {
                                        optActive == "observation" ?
                                        <div className="text text_href" dangerouslySetInnerHTML={ { __html: course.observation ? course.observation.replaceAll('&#34;', '"') : "" } } /> : null
                                    }
                                </div>
                            </>
                        }
                    </div>
                    
                    {
                        showModules.length > 0 ?
                        <div className="list_module">
                            {
                                showModules.map((elem, index)=>{ 
                                    let infCourse = classeCont.filter(item => item.id_course == GetListPag('pageDash_id_course'));
                                    
                                    let listClasse = infCourse.filter(item => item.id_module == elem.id);
                                    let continues = listClasse.filter(item => item.status == 1);

                                    let qtdClasse = 0;
                                    if(user.nivel == 3){                                        
                                        qtdClasse = elem.list_classe;
                                    }else {
                                        qtdClasse = elem.list_classe.filter(item => item.type == 'classe');
                                    }

                                    return(
                                        <div className="div_module" key={ index }>
                                            <div className="show_modules" onClick={ ()=>{ OpenMoodule("accordion_" + index, index, !elem.status, elem.list_classe.length); CheckedTextA(); } }>
                                                <div className="icon_arrow">
                                                    {
                                                        elem.status == false ? 
                                                        <SvgSetaDown className="icons arrow_rotate" color="#ffffff" /> :
                                                        <SvgSetaDown className="icons" color="#ffffff" />
                                                    }
                                                </div> 
                                                <div className="">
                                                    <div className="name_modules">{ elem.name }</div>
                                                </div>                                            
                                                <div className="qtd_classes">
                                                    <div className="">
                                                        { continues.length }/{ qtdClasse.length }
                                                    </div>
                                                </div>                                            
                                            </div>
                                            <div className="div_list_classes" id={ "accordion_" + index }>
                                                {
                                                    elem.list_classe.map((elem_1, index_1)=>{
                                                        if(elem_1.type == 'upload'){
                                                            if(user.nivel == 3){
                                                                return(
                                                                    <div className={ elem_1.status ? "list_classes classes_active" : "list_classes" } key={ index_1 } onClick={ ()=>{ CheckedTextA() } }>
                                                                        {
                                                                            CheckedStatusClasse(elem_1, elem.id, elem_1.id)
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        }else {
                                                            return(
                                                                <div className={ elem_1.status ? "list_classes classes_active" : "list_classes" } key={ index_1 } onClick={ ()=>{ CheckedTextA() } }>
                                                                    {
                                                                        CheckedStatusClasse(elem_1, elem.id, elem_1.id)
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                conclusion == 0 ? null :
                                    conclusion == 1 ? 
                                <div className="div_module">
                                    <div className="show_modules questions" onClick={ ()=>{ setShowConcl(true) } }>
                                        Responder questionário
                                    </div>
                                </div>
                                : 
                                <div className="div_module">
                                    <div className="show_modules questions response">
                                        Questionário Repondido
                                    </div>
                                </div>
                            }
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}